import React, { Component } from 'react'
import styles from 'assets/scss/Qualification.module.scss';

export default class Create extends Component {

    state = {
        anggota_1: '',
        anggota_2: '',
        anggota_3: '',
        anggota_4: '',
        anggota_5: '',
    }

    componentDidMount() {
        //     this.populateMember();
        this.resetColumn();
    }

    componentDidUpdate() {
        //     this.populateMember();
        this.resetColumn();
    }

    resetColumn = () => {
        const { stage_detail } = this.props.main;
        let allField = document.querySelectorAll('input.anggota_field');
        if (stage_detail?.stage_info?.is_captain) {
            allField.forEach(dt => {
                dt.value = ''
                dt.removeAttribute('disabled')
            })
        }
    }

    populateMember = (number) => {
        this.resetColumn();

        const { stage_detail } = this.props.main;
        // if (stage_detail?.stage_info?.members) {

        //     let data = stage_detail?.stage_info?.members;
        //     console.log(data)
        //     data.forEach(dt => {
        //         // let anggota = document.querySelector(`input[name="anggota_${dt.member_no}"]`);
        //         let anggota = document.getElementById(`anggota_${dt.member_no}_input`)
        //         if(anggota) {
        //             console.log(anggota)
        //             anggota.setAttribute('disabled','disabled');
        //             document.getElementById(`anggota_${dt.member_no}_input`).value = decodeURIComponent(dt.codm_nickname)
        //         }
        //     });
        // }
        if (stage_detail?.stage_info?.members) {
            let data = stage_detail?.stage_info?.members;
            let filtered = data.filter((dt) => dt.member_no === number)

            if (filtered.length > 0) {
                return decodeURIComponent(filtered[0].codm_nickname);
            }
            else {
                return ""
            }
        }
        else {
            return ""
        }
    }

    renderInvite = (number) => {
        const { assets_base_path,stage_detail } = this.props.main;

        if (stage_detail?.stage_info?.is_captain === false) {
            return false;
        }

        if (stage_detail?.stage_info?.status === "empty") {
            return (
                <div className={styles.btn} onClick={(e) => this.handleInvite(e, number)}>
                    <img className="img_btn_1" src={`${assets_base_path}btn_slice.png`} alt="" />
                </div>
            )
        }

        if (stage_detail?.stage_info?.members) {
            let data = stage_detail?.stage_info?.members;
            let filtered = data.filter((dt) => dt.member_no === number)

            if (filtered.length > 0) {
                if (filtered[0].status === "dissolved" || filtered[0].status === "reject" || filtered[0].status === "joined") {
                    return false;
                } else {
                    return (
                        <div className={styles.btn} onClick={(e) => filtered[0].status !== "init" ? this.handleInvite(e, number) : ""}>
                            <img className="img_btn_1" src={`${assets_base_path}${filtered[0].status === "init" ? "btn_menunggu.png" : "btn_slice.png"}`} alt="" />
                        </div>
                    )
                }
            }
            else {
                return (
                    <div className={styles.btn} onClick={(e) => this.handleInvite(e, number)}>
                        <img className="img_btn_1" src={`${assets_base_path}btn_slice.png`} alt="" />
                    </div>
                )
            }
        }
    }

    renderKick = (number) => {
        const { stage_detail } = this.props.main;

        if (stage_detail?.stage_info?.is_captain === false) {
            return false;
        }

        if (stage_detail?.stage_info?.members) {
            let data = stage_detail?.stage_info?.members;
            let filtered = data.filter((dt) => dt.member_no === number)

            if (filtered.length > 0) {
                if (filtered[0].status === "dissolved" || filtered[0].status === "reject") {
                    return false;
                } else {
                    return (
                        <span className={styles.kick_btn} onClick={() => this.handleKick(filtered[0].account_id, filtered[0].codm_nickname)}>X</span>
                    )
                }
            }
        }
    }

    handleSubmit = (e) => {
        e.preventDefault()
        const { put_data, toggle_popup, confirm } = this.props.actionsMain;
        let team_name = document.querySelector('input[name="team_name"]');
        let text = `Apakah kamu yakin ingin membuat tim ini?`;

        let data = {
            team_name: team_name.value
        }

        put_data('popup_text', text);
        put_data('popup_callback', () => confirm(data));
        toggle_popup(true, 'confirm');
    }

    handleInvite = (e, type) => {
        let team_name = document.querySelector('input[name="team_name"]');
        let anggota = document.querySelector(`input[name="anggota_${type}"]`);

        let data = {
            team_name: team_name.value,
            open_id: anggota.value,
            member_no: type,
            is_reserve: type === 5
        }

        this.props.actionsMain.invite(data);
    }

    getCaptain = () => {
        const { stage_detail, login } = this.props.main;

        if (stage_detail?.stage_info?.is_captain === true) {
            return decodeURIComponent(login.codm_nickname)
        }

        if (stage_detail?.stage_info?.members) {
            let data = stage_detail?.stage_info?.members;
            let filtered = data.filter((dt) => dt.status === 'captain')

            if (filtered.length > 0) {
                return decodeURIComponent(filtered[0].codm_nickname);
            }
            else {
                return ""
            }
        }
        else {
            return ""
        }
    }

    handleKick = (open_id, name) => {
        const { put_data, toggle_popup, kick } = this.props.actionsMain;

        let payload = {
            open_id: open_id
        }

        let text = `Apakah kamu yakin untuk mengeluarkan ${decodeURIComponent(name)} dari tim?`;
        put_data('popup_text', text);
        put_data('popup_callback', () => kick(payload));
        toggle_popup(true, 'confirm');
    }

    handleTeamName = (e) => {
        let val = e.currentTarget.value;

        if (val.length >= 20) {
            e.preventDefault();
            e.stopPropagation();
        }
    }

    handleDissolve = () => {
        const { put_data, toggle_popup } = this.props.actionsMain;
        let text = `Apakah kamu yakin ingin membubarkan tim ini?`;
        put_data('popup_text', text);
        put_data('popup_callback', this.DissolveCallback);
        toggle_popup(true, 'confirm');
    }

    DissolveCallback = () => {
        this.props.actionsMain.dissolve();
        let el = document.querySelector("input[name='team_name']");
        el.value = "";
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({
            [name]: value
        })
    }

    renderMember = (number) => {
        const { stage_detail, json } = this.props.main;
        const { is_captain } = stage_detail?.stage_info;

        if (this.populateMember(number)) {
            return (<input type="text" name={`anggota_${number}`} value={this.populateMember(number)} readOnly />)
        } else {
            return (<input type="number"
                placeholder={is_captain ? json?.topenid_placeholder : json?.invited_placeholder} className="anggota_field"
                id={`anggota_${number}_input`}
                name={`anggota_${number}`}
                onChange={(e) => { this.handleChange(e) }}
                value={this.state[`anggota_${number}`]}
                disabled={is_captain ? false : true}
            />
            )
        }
    }

    render() {
        const { assets_base_path,stage_detail } = this.props.main;
        return (
            <div className="animate__animated animate__fadeInUp">
                <div className={styles.stage}>
                    <div className={styles.create_team}>
                        Selamat! Kamu berhasil masuk ke Babak Kualifikasi Tim. Buat Tim sekarang!
                    </div>
                </div>
                <form className={styles.team_form} onSubmit={(e) => this.handleSubmit(e)}>
                    <div className={styles.team_name}>
                        <div className={styles.field}>
                            <label htmlFor="">Nama Tim</label>
                            <input type="text" name="team_name" required defaultValue={stage_detail?.stage_info?.team_name} disabled={stage_detail?.stage_info?.is_captain ? false : true} onKeyPress={(e) => this.handleTeamName(e)} />
                        </div>

                        <div className={styles.btn_cont} style={stage_detail?.stage_info?.is_captain ? {} : { display: 'none' }}>
                            <div className={styles.btn_submit + " " + styles.btn_bubar} onClick={this.handleDissolve} style={stage_detail?.stage_info?.status !== "empty" ? {} : { display: 'none' }}>
                                <img src={assets_base_path+"btn_bubar.png"} alt="" />
                            </div>
                            <button type="submit" className={styles.btn_submit}>
                                <img src={assets_base_path+"btn_konfirmasi.png"} alt="" />
                            </button>
                        </div>
                    </div>
                    <div className={styles.team_member}>
                        <div className={styles.field}>
                            <label htmlFor="">CAPTAIN</label>
                            <input type="text" name="" defaultValue={this.getCaptain()} readOnly />
                        </div>
                        <div className={styles.field}>
                            <label htmlFor="">Anggota 1</label>
                            <div className={styles.input_cont}>
                                {this.renderMember(1)}
                                {this.renderKick(1)}
                            </div>
                            {this.renderInvite(1)}
                        </div>
                        <div className={styles.field}>
                            <label htmlFor="">Anggota 2</label>
                            <div className={styles.input_cont}>
                                {this.renderMember(2)}
                                {this.renderKick(2)}
                            </div>
                            {this.renderInvite(2)}
                        </div>
                        <div className={styles.field}>
                            <label htmlFor="">Anggota 3</label>
                            <div className={styles.input_cont}>
                                {this.renderMember(3)}
                                {this.renderKick(3)}
                            </div>
                            {this.renderInvite(3)}
                        </div>
                        <div className={styles.field}>
                            <label htmlFor="">Anggota 4</label>
                            <div className={styles.input_cont}>
                                {this.renderMember(4)}
                                {this.renderKick(4)}
                            </div>
                            {this.renderInvite(4)}
                        </div>
                        <div className={styles.field}>
                            <label htmlFor="">Cadangan</label>
                            <div className={styles.input_cont}>
                                {this.renderMember(5)}
                                {this.renderKick(5)}
                            </div>
                            {this.renderInvite(5)}
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}
