function generateCSSVariable(localize) {
	const data = Object.entries(localize).filter(([key, value]) =>
		isString(value) && (isTypeImages(value) || isTypeColor(key))
	);
	const target = document.documentElement;
	data.forEach(([key, value]) => {
		const newValue = isTypeImages(value) ? replaceSpace(value) : value;
		setCSSVariable({ target, key, value: newValue });
	});
}

function replaceSpace(value) {
	return `url(${value.replaceAll(" ", "%20")})`;
}

function isString(value) {
	return typeof value === "string";
}

function isTypeImages(value) {
	return typeof value === "string" && value.match(/\.(?:png|jpe?g|gif|png|swf)$/);
}

function isTypeColor(key) {
	return key.toLowerCase().startsWith("clr");
}

function replaceKeytoVar(key) {
	return "--" + key.replace(/_/g, "-").toLowerCase();
}

function setCSSVariable({ target = document.documentElement, key, value }) {
	target.style.setProperty(replaceKeytoVar(key), value);
}

export default generateCSSVariable;
