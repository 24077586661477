import React, { Component } from 'react'
import styles from 'assets/scss/Qualification.module.scss';

export default class Team extends Component {

    handleDissolve = () => {
        const {put_data, toggle_popup, dissolve} = this.props.actionsMain;
        let text = `Apakah kamu yakin ingin membubarkan tim ini?`;
        put_data('popup_text', text);
        put_data('popup_callback', dissolve);
        toggle_popup(true, 'confirm');
    }

    populateRound = () => {
        const {stage_detail,assets_base_path} = this.props.main;
        let list = new Array(30).fill(null);
        
        let data = stage_detail?.stage_info?.win_list;
        if (data) {
            for (let index = 0; index < data.length; index++) {
                list[index] = data[index]
            }
        }

        return list.map((dt, idx) => {
            let img = '';
            switch (dt) {
                case true:
                    img = 'kualifikasi_win'
                    break;
                case false:
                    img = 'kualifikasi_lose'
                    break;
                default:
                    img = 'kualifikasi_not announced'
                    break;
            }

            return (
                <div key={idx} className={styles.round_item}>
                    <img src={`${assets_base_path}${img}.png`} alt="" />
                </div>
            )

        });
    }

    populateMember = () => {
        const {stage_detail} = this.props.main;
        let data = stage_detail?.stage_info?.members;
        let list = []

        if (data) {
            list = data.map((dt, idx) => {
                return <p key={idx}>{decodeURIComponent(dt.codm_nickname)}</p>
            })
        }

        return list;
    }

    renderPrize = (type, req = null) => {
        if (type === 0) {
            return ( <p>PLAY {req} MATCH</p> )
        }
        else
        {
            return ( <p>HADIAH TOP 192 TEAM</p> )
        }
    }

    renderText = () => {
        const {stage} = this.props.main;

        // GET CURRENT STAGE DETAIL
        let filtered = stage.stages[stage.stages.length-1]

        if (filtered) {
            if (filtered.is_finished === true) {
                return "TELAH BERAKHIR" 
            }
            else if (filtered.is_open === true) {
                return "SEDANG BERJALAN"
            }
            else
            {
                return "BELUM DIMULAI"
            }
        }
        else
        {
            return "BELUM DIMULAI"
        }
    }

    populateReward = () => {
        const {stage_detail} = this.props.main;
        let list = [];

        if (stage_detail?.rewards) {
            list = stage_detail.rewards.map((data, idx) => 
                <div key={idx} className={styles.prize}>
                    <div className={styles.text}>
                        { data.claim_text }
                    </div>
                    <div 
                        className={`${styles.icon} ${(data.can_claim && !data.is_claimed) ? styles.active : ''} ${(data.is_claimed) ? styles.disabled : ''}`} 
                        onClick={() => (data.can_claim && !data.is_claimed) ? this.props.actionsMain.claim(data.id) : ""}
                    >
                        <img src={data.img_url} alt=""/>
                    </div>
                </div>
            )
        }

        return list;
    }

    render() {
        const {stage_detail,assets_base_path} = this.props.main;

        return (
            <div className={"animate__animated animate__fadeInUp " + styles.team_view}>
                <div className={styles.stage}>
                    <div className={styles.current_stage}>
                        Kualifikasi Tim : {this.renderText()}
                    </div>
                    <div className={styles.rank}>
                        Rata-Rata Rank : {stage_detail?.stage_info?.avg_rank} (+{stage_detail?.stage_info?.win_point})
                    </div>
                </div>

                <div className={styles.team_detail}>
                    <div className={styles.row}>
                        <div className={styles.col}>RANK</div>
                        <div className={styles.col}>NAMA TIM</div>
                        <div className={styles.col}>POIN</div>
                        <div className={styles.col}>REKAN TIM</div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.col}>No. {stage_detail?.stage_info?.leaderboard_rank}</div>
                        <div className={styles.col}>{stage_detail?.stage_info?.team_name}</div>
                        <div className={styles.col}>{stage_detail?.stage_info?.total_points}</div>
                        <div className={styles.col}>{this.populateMember()}</div>
                    </div>
                </div>

                <div className={styles.round}>
                    <div className={styles.title}>
                        <div className={styles.text}>
                            RONDE GAME {stage_detail?.stage_info?.win_list?.length}/{stage_detail?.stage_info?.match_cap}
                        </div>
                        <div className={styles.stats}>
                            <div className={styles.stat_item}>
                                <div className={styles.icon}>
                                    <img src={assets_base_path+"kualifikasi_win.png"} alt="" />
                                </div>
                                <div className={styles.text}>
                                    MENANG: {stage_detail?.stage_info?.win}
                                </div>
                            </div>
                            <div className={styles.stat_item}>
                                <div className={styles.icon}>
                                    <img src={assets_base_path+"kualifikasi_lose.png"} alt="" />
                                </div>
                                <div className={styles.text}>
                                    KALAH: {stage_detail?.stage_info?.lose}
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className={styles.round_items}>
                        {this.populateRound()}
                    </div>
                </div>

                <div className={styles.bottom_cont}>
                    <div className={styles.prizes}>
                        {this.populateReward()}
                    </div>
                    <div className={styles.bubar} onClick={this.handleDissolve}>
                        <img src={assets_base_path+"btn_bubar.png"} alt=""/>
                    </div>
                </div>
            </div>
        )
    }
}
