import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import styles from 'assets/scss/Layout.module.scss'

// REDUX
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as mainActions from 'redux/actions/main';

class Layout extends Component {
    
    handleActive = (data) => {
        const {pathname} = window.location;
        if (pathname.indexOf(data) > -1) {
            return styles.active;
        }
    }

    render() {
        const {assets_base_path,stage} = this.props.main;
        
        return (
            <div className={styles.layout}>
                <div className={styles.content}>
                    <div className={styles.tab}>
                        <Link to="/hadiah" className={`${styles.tab_item} ${this.handleActive('hadiah')}`}>
                            <img src={assets_base_path+"btn_menu_info hadiah_inactive.png"} alt="" />
                        </Link>
                        <Link to="/kualifikasi" className={`${styles.tab_item} ${this.handleActive('kualifikasi')}`} onClick={() => this.props.actionsMain.stage_detail(stage.active)}>
                            <img src={assets_base_path+"btn_menu_kualifikasi_inactive.png"} alt="" />
                        </Link>
                        <Link to="/leaderboard" className={`${styles.tab_item} ${this.handleActive('leaderboard')}`}>
                            <img src={assets_base_path+"btn_menu_leaderboard tim_inactive.png"} alt="" />
                        </Link>

                        <Link to="/peraturan" className={`${styles.tab_item} ${this.handleActive('peraturan')}`}>
                            <img src={assets_base_path+"btn_menu_peraturan_inactive.png"} alt="" />
                        </Link>
                    </div>
                    <div className={styles.board}>
                        {this.props.children}
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
	return { main: state.main }
}

function mapDispatchToProps(dispatch) {
	return {
		actionsMain: bindActionCreators(mainActions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout);